const DefaultState = {
    loading: false,
    data: {},
    errorMsg: "",
    input : "",
    companyData: []
};

const companyReducer = (state = DefaultState, action) => {
    switch (action.type) {
        case "COMPANY_SEARCH_LOADING":
            return {
                ...state,
                loading: true,
                errorMsg: ""
            };
        case "COMPANY_SEARCH_FAIL" :
            return {
                ...state,
                loading: false,
                errorMsg: "Unable to find company"
            };
        case "COMPANY_SEARCH_SUCCESS" :
            return {
                ...state,
                loading: false,
                errorMsg: "",
                data: {
                    ...state.data,
                    [action.companyName] : action.payload
                }
            };
        case "INPUT_UPDATION" :
            return {
                ...state,
                input: action.payload
            }
        default:
            return state
    }
}

export default companyReducer