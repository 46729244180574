import React from "react";
import SectionTitle from "../SectionTitle";
import { motion } from "framer-motion";

// from-white to-[#E8F5FF]

const VideoSection = () => {
  return (
    <section className="pt-24 sm:px-4 lg:bg-gradient-to-b to-white from-[#E8F5FF]">
      <div className="w-full h-full lg:py-16 lg:px-12 overflow-x-hidden xl:container">
        <div className="flex gap-12 lg:flex-row sm:flex-col">
          <div className="flex flex-1 aspect-video">
            <iframe
              className="rounded-xl w-full aspect-video"
              src="https://www.youtube.com/embed/3bwUxXTG0P0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>

          {/* 2 */}
          <div className=" flex-1 relative overflow-auto">

            <div>
              <SectionTitle text={"Make GEN Ai Work for you"} />
              <div className="w-fit">
                <h2
                  className="font-bold lg:text-4xl sm:text-2xl whitespace-normal sm:leading-2 lg:leading-10 relative z-20"
                  style={{ letterSpacing: "-0.03em" }}
                >
                  Boost your bottom-line  <br /> with AI agents
                </h2>
              </div>
              <motion.svg
                className="relative -top-2 lg:w-56 sm:w-36"
                height="19"
                viewBox="0 0 478 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "loop",
                    repeatDelay: 2,
                  }}
                  d="M3.94805 15.7609C84.6154 -0.425883 274.277 0.149277 474.307 15.8478"
                  stroke="#3E91EE"
                  strokeWidth="6"
                  strokeLinecap="round"
                />
              </motion.svg>

              <div className="">
                <p
                  className="font-normal text-sm leading-6 w-fit"
                  style={{ color: "#71717A" }}
                >
                  Switch to the Next Generation of operations with Artificial
              Intelligence and Machine Learning alternatives. Our team of
              Natural Language Processing and Computer Vision experts help you
              draw the perfect data transformation strategy. Our platforms have
              been re-energizing Private Equity and Venture Capital markets and
              our bespoke Ai solutions help monetize data for startups across
              the globe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
