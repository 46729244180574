import React from "react";
import mainImg from '../../assets/AOT/analyze-aot.svg';
import { motion } from "framer-motion";
import SectionTitle from "../SectionTitle";

const AOT_Section = () => {

    return (<section className="sm:px-4 sm:bg-none h-screen lg:bg-gradient-to-b to-white from-[#E8F5FF]">
        <div className="w-full h-full overflow-x-hidden xl:container flex flex-col justify-center ">
            <div className="flex lg:flex-row-reverse sm:flex-col justify-between sm:items-center lg:items-center">
                <div
                    className="flex mt-0 flex-col items-center justify-center gap-12 sm:w-full lg:w-1/2"
                    style={{ height: "400px" }}
                >
                    <div>
                        <SectionTitle text={"Analyze IoT data in real-time"} />
                        <div className="lg:w-[450px] sm:w-[350px]">
                            <h2
                                className="font-bold lg:text-4xl sm:text-2xl whitespace-normal sm:leading-2 lg:leading-10 relative z-20"
                                style={{ letterSpacing: "-0.03em" }}
                            >
                                Analyze IoT data in real-time and drive meaningful change.
                            </h2>
                        </div>
                        <motion.svg
                            // className="relative -top-2 lg:w-72 sm:w-48"
                            className="relative left-16 -top-2 lg:w-64 sm:w-48"
                            height="19"
                            viewBox="0 0 478 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <motion.path
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: 1 }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                    repeat: Infinity,
                                    repeatType: "loop",
                                    repeatDelay: 2,
                                }}
                                d="M3.94805 15.7609C84.6154 -0.425883 274.277 0.149277 474.307 15.8478"
                                stroke="#3E91EE"
                                strokeWidth="6"
                                strokeLinecap="round"
                            />
                        </motion.svg>

                        <div className="">
                            <p
                                className="font-normal text-sm leading-6 lg:w-96 sm:w-80"
                                style={{ color: "#71717A" }}
                            >
                                In a world where devices are interconnected, the data they generate holds the key to smarter decisions and better outcomes
                            </p>
                        </div>
                        <div className="flex flex-row justify-between sm:mt-5 lg:mt-8 gap-4">
                            <a      
                                href="mailto:connect@factacy.ai?Subject=Aot enquiry"
                                className="bg-buttonCTA py-4 lg:px-8 sm:px-5 rounded-full text-base leading-6 font-normal text-white w-fit h-14"
                            >
                                Start your AOT journey
                            </a>
                        </div>
                    </div>
                </div>

                {/* 2 */}
                <div className="flex flex-col items-end lg:w-1/2">
                    <img src={mainImg} alt="" className=" w-[40rem]  object-cover" />
                </div>
            </div>
        </div>
    </section>)
}
export default AOT_Section;