import React from "react";

const Twitter = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15.0586" r="15" fill="#3E91EE" />
      <g clipPath="url(#clip0_311_3013)">
        <path
          d="M21.6131 11.668C21.1504 11.8726 20.6597 12.0071 20.1573 12.0668C20.6869 11.7501 21.0832 11.2516 21.2725 10.6643C20.7755 10.9601 20.2307 11.1674 19.6628 11.2795C19.2813 10.8714 18.7757 10.6007 18.2246 10.5095C17.6734 10.4184 17.1076 10.5119 16.6151 10.7755C16.1225 11.0391 15.7309 11.4581 15.501 11.9672C15.2712 12.4764 15.216 13.0472 15.344 13.591C14.3362 13.5405 13.3503 13.2786 12.4502 12.8223C11.5502 12.366 10.7562 11.7256 10.1198 10.9425C9.89449 11.3295 9.7761 11.7693 9.77674 12.2171C9.77674 13.0959 10.224 13.8722 10.904 14.3268C10.5016 14.3141 10.108 14.2054 9.75613 14.0098V14.0413C9.75625 14.6266 9.95878 15.1938 10.3294 15.6468C10.7 16.0998 11.2158 16.4107 11.7895 16.5268C11.4159 16.628 11.0242 16.6429 10.644 16.5704C10.8057 17.0742 11.121 17.5148 11.5456 17.8305C11.9702 18.1462 12.4829 18.3212 13.0119 18.331C12.4861 18.7439 11.8841 19.0492 11.2403 19.2293C10.5965 19.4094 9.92348 19.4609 9.25977 19.3807C10.4184 20.1258 11.7671 20.5214 13.1446 20.5201C17.807 20.5201 20.3567 16.6577 20.3567 13.308C20.3567 13.1989 20.3537 13.0886 20.3489 12.9807C20.8451 12.622 21.2735 12.1777 21.6137 11.6686L21.6131 11.668Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_311_3013">
          <rect
            width="14.5455"
            height="14.5455"
            fill="white"
            transform="translate(8.18164 8.24023)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
