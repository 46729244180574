import React from "react";

const FactacyFullIcon = () => {
  return (
    <svg
      className="sm:w-60"
      width="311"
      height="41"
      viewBox="0 0 311 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_311_2978)">
        <path
          d="M176.19 1.33838V10.5891H160.396V38.7863H149.864V10.5891H134.017V1.33838H176.19Z"
          fill="#3E91EE"
        />
        <path
          d="M298.461 1.33838L288.197 16.3175L277.984 1.33838H265.859L282.931 24.4485V38.7856H293.461V24.4485L310.533 1.33838H298.461Z"
          fill="#3E91EE"
        />
        <path
          d="M43.7234 1.33838H0.0244141V11.9314H43.7234V1.33838Z"
          fill="#3E91EE"
        />
        <path
          d="M22.4938 22.5244H0.0244141V33.1182H22.4938V22.5244Z"
          fill="#3E91EE"
        />
        <path
          d="M88.0247 38.7902H76.7978L62.9043 12.1503L48.9916 38.7902H37.7832L37.8409 38.6734L57.3589 1.34521H68.469L88.0247 38.7902Z"
          fill="#3E91EE"
        />
        <path
          d="M221.362 38.7902H210.134L196.243 12.1503L182.329 38.7902H171.12L171.179 38.6734L190.695 1.34521H201.807L221.362 38.7902Z"
          fill="#3E91EE"
        />
        <path
          d="M112.142 30.6219C104.07 30.6219 97.2677 25.7863 97.2677 20.0653C97.2677 14.3294 104.07 9.49536 112.142 9.49536C116.767 9.49536 120.997 11.1018 123.751 13.5541H123.764L130.265 7.00131C125.845 2.81752 119.357 0.199219 112.142 0.199219C98.8366 0.199219 88.0254 9.10696 88.0254 20.0653C88.0254 31.0095 98.8366 39.9165 112.142 39.9165C119.357 39.9165 125.845 37.299 130.265 33.1293L123.751 26.5757C120.997 29.0289 116.767 30.6219 112.142 30.6219Z"
          fill="#3E91EE"
        />
        <path
          d="M245.476 30.6219C237.407 30.6219 230.603 25.7863 230.603 20.0653C230.603 14.3294 237.407 9.49536 245.476 9.49536C250.105 9.49536 254.334 11.1018 257.088 13.5541H257.102L263.603 7.00131C259.18 2.81752 252.695 0.199219 245.476 0.199219C232.174 0.199219 221.362 9.10696 221.362 20.0653C221.362 31.0095 232.174 39.9165 245.476 39.9165C252.695 39.9165 259.18 37.299 263.603 33.1293L257.088 26.5757C254.334 29.0289 250.105 30.6219 245.476 30.6219Z"
          fill="#3E91EE"
        />
      </g>
      <defs>
        <clipPath id="clip0_311_2978">
          <rect
            width="310.667"
            height="40"
            fill="white"
            transform="translate(0 0.0585938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FactacyFullIcon;
