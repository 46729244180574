import React from 'react'

const Errorpage = () => {
  return (
    <div className='h-screen relative text-center justify-center flex flex-col px-6'>
      <p className='font-bold text-5xl'>Error 404</p>
      <p>Whoops! I think you meant to search something else. </p>
    </div>
  )
}

export default Errorpage