import React from "react";
import SectionTitle from "../SectionTitle";
import inderjit from "../../assets/WheelsImages/inderjit.jpg";
import manoj from "../../assets/WheelsImages/manoj.jpg";
import jas from "../../assets/WheelsImages/jasmine.jpg";
import satarupa from "../../assets/WheelsImages/satarupa.jpg";

import annu from "../../assets/WheelsImages/annu.jpg";
import atin from "../../assets/WheelsImages/atin.jpg";
import hritwiza from "../../assets/WheelsImages/hritwiza.jpg";
import imran from "../../assets/WheelsImages/imran.jpg";
import manish from "../../assets/WheelsImages/manish.jpeg";
import sukriti from "../../assets/WheelsImages/sukriti.jpg";
import kushagra from "../../assets/WheelsImages//kushagra.jpg";

// -----------------------------------------------------------
import abhinandan from "../../assets/WheelsImages/abinandan.jpg";
import arshad from "../../assets/WheelsImages/arshad.jpg";
import aryan from "../../assets/WheelsImages/aryan.jpg";
import kunal from "../../assets/WheelsImages/kunal.jpg";
import saumya from "../../assets/WheelsImages/saumya.jpg";
import shreya from "../../assets/WheelsImages/shreya.png";
import shruti from "../../assets/WheelsImages/shruti.jpg";
import anuj from "../../assets/WheelsImages/anuj.jpg";
import yash from "../../assets/WheelsImages/yash.jpg";
import anish from "../../assets/WheelsImages/anish.jpg";

import { motion, useAnimation } from "framer-motion";

const Image = ({ src, classes, rotation }) => (
  <img
    src={src}
    className={` absolute w-16 h-16 rounded-full ${
      rotation === "clockWise" ? "animate-spin" : "animate-spinReverse"
    } ${classes} `}
  />
);
const Text = ({ text, classes, rotation }) => (
  <span
    className={`absolute px-2.5 py-1.5 text-xs font-bold text-colorText rounded-full uppercase ${
      rotation === "clockWise" ? "animate-spin" : "animate-spinReverse"
    } ${classes} `}
  >
    {text}
  </span>
);

const Team = () => {
  return (
    <section>
      <div className=" relative flex items-center justify-center p-12 lg:bg-gradient-to-b from-white to-[#E8F5FF] h-[75rem] w-full">
        {/* main text */}
        <div className="flex flex-col items-center justify-center z-40">
          <SectionTitle text={"our team"} />
          <div className="w-96">
            <h2
              className="font-bold text-[2.5rem] text-center relative z-20"
              style={{ lineHeight: "50px", letterSpacing: "-0.02em" }}
            >
              An Experienced
            </h2>
            <motion.svg
              className=" ml-28 -mt-2 z-10"
              width="230"
              height="17"
              viewBox="0 0 280 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "loop",
                  repeatDelay: 2,
                }}
                d="M3.02559 13.0286C49.9788 -0.0924362 160.383 0.424759 276.827 13.2237"
                stroke="#3E91EE"
                strokeWidth="6"
                strokeLinecap="round"
              />
            </motion.svg>
            <h2
              className="font-bold text-[2.5rem] -mt-2 text-center relative z-20"
              style={{ lineHeight: "50px", letterSpacing: "-0.02em" }}
            >
              Team With You
            </h2>
          </div>
          <div className="flex mt-2 gap-4">
            <a
              className="bg-buttonCTA py-4 px-8 rounded-full text-base leading-6 font-normal text-white w-fit h-14"
              href="/"
            >
              Learn More
            </a>
            <a
              className=" py-4 px-8 rounded-full text-base leading-5 font-normal border-2 border-black h-14 w-fit hover:bg-black hover:text-white"
              href="/career"
            >
              Join The Team
            </a>
          </div>
        </div>

        {/* outer whell */}
        <div
          className=" absolute border-4 h-[60rem] z-0 w-[60rem] rounded-full animate-spinReverse"
          style={{ border: "1px solid #b9e0ff" }}
        >
          <Image
            rotation={"clockWise"}
            src={shruti}
            classes={"-top-[2.5%] right-[38%]"}
          />
          <Text
            text={"data migration"}
            classes={"bg-yellow top-[5%] right-[18%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={arshad}
            classes={"top-[12%] right-[10%]"}
          />
          <Text
            text={"data quality"}
            classes={"bg-green top-[24%] right-[1%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={aryan}
            classes={"top-[35%] -right-[2%]"}
          />
          <Text
            text={"data governence"}
            classes={"bg-red top-[52%] -right-[7%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={kunal}
            classes={"bottom-[30%] right-[0%]"}
          />
          <Text
            text={"business intelligence"}
            classes={"bg-purple bottom-[20%] right-[2%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={saumya}
            classes={"bottom-[8%] right-[16%]"}
          />
          <Text
            text={"cloud based analytics"}
            classes={"bg-blue bottom-[2%] right-[22%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={shreya}
            classes={"-bottom-[3%] left-[45%]"}
          />
          <Text
            text={"big data analytics"}
            classes={"bg-yellow bottom-[2%] left-[25%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={abhinandan}
            classes={"bottom-[8%] left-[15%]"}
          />
          <Text
            text={"machine learning"}
            classes={"bg-green bottom-[20%] left-[2%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={anuj}
            classes={"bottom-[32%] -left-[1%]"}
          />
          <Text
            text={"realtime analytics"}
            classes={"bg-red bottom-[50%] -left-[8%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={yash}
            classes={"top-[30%] -left-[0%]"}
          />
          <Text
            text={"large language models"}
            classes={"bg-purple top-[18%] left-[0%]"}
            rotation={"clockWise"}
          />
          <Image
            rotation={"clockWise"}
            src={anish}
            classes={"top-[4%] left-[20%]"}
          />
          <Text
            text={"predictive analytics"}
            classes={"bg-blue top-[0%] left-[30%]"}
            rotation={"clockWise"}
          />
        </div>

        {/* middle whell */}
        <div
          className=" absolute border-4 h-[45rem] z-10 w-[45rem] rounded-full animate-spin"
          style={{ border: "1px solid #b9e0ff" }}
        >
          <Text
            text={"digital transformation"}
            classes={"bg-red top-[5%] right-[11%]"}
          />
          <Image src={annu} classes={"top-[20%] right-[3%]"} />
          <Text
            text={"nano tech"}
            classes={"bg-purple top-[40%] -right-[6%]"}
          />
          <Image src={atin} classes={"top-[54%] -right-[4%]"} />
          <Text
            text={"generative ai"}
            classes={"bg-yellow bottom-[23%] right-[0%]"}
          />
          <Image src={hritwiza} classes={"bottom-[5%] right-[16%]"} />
          <Text
            text={"ai as a service"}
            classes={"bg-green bottom-[0%] right-[32%]"}
          />
          <Image src={imran} classes={"bottom-[1%] left-[24%]"} />
          <Text
            text={"augmented analytics"}
            classes={"bg-red bottom-[18%] left-[0%]"}
          />
          <Image src={kushagra} classes={"bottom-[36%] -left-[4%]"} />

          <Text
            text={"analytics of things"}
            classes={"bg-purple top-[34%] -left-[10%]"}
          />
          <Image src={manish} classes={"top-[12%] left-[7%]"} />

          <Text
            text={"data integration"}
            classes={"bg-blue top-[4%] left-[15%]"}
          />
          <Image src={sukriti} classes={"top-[-5%] left-[50%]"} />
        </div>

        {/* inner whell */}
        <div
          className="absolute w-[30rem] h-[30rem] z-20 rounded-full animate-spinReverse"
          style={{ border: "1px solid #b9e0ff" }}
        >
          <div className="relative w-full h-full z-20">
            <Image
              src={inderjit}
              classes={"-top-[5%] right-[28%]"}
              rotation={"clockWise"}
            />
            <Text
              text={"INTERNET OF THINGS"}
              classes={"bg-purple top-[22.5%] -right-[10%]"}
              rotation={"clockWise"}
            />
            <Image
              src={manoj}
              classes={"top-[50%] -right-[5%]"}
              rotation={"clockWise"}
            />
            <Text
              text={"edge analytics"}
              classes={"bg-blue bottom-[5%] right-[12%]"}
              rotation={"clockWise"}
            />
            <Image
              src={satarupa}
              classes={"bottom-[2%] left-[16%] "}
              rotation={"clockWise"}
            />
            <Text
              text={"sensor data"}
              classes={"bg-yellow bottom-[35%] -left-[10%]"}
              rotation={"clockWise"}
            />
            <Image
              src={jas}
              classes={"top-[25%] -left-[4%]"}
              rotation={"clockWise"}
            />
            <Text
              text={"data warehousing"}
              classes={"bg-green top-[5%] left-[8%]"}
              rotation={"clockWise"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Team;