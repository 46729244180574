import React from "react";
import { motion, useAnimation } from "framer-motion";
import manufacturing from "../../assets/factacy_ai_solutions_for_manufacturing.png";
import netAddition from "../../assets/factacy_ai_solutions_net_addition_towards_economy.jpg";
import banking from "../../assets/factacy_ai_solutions_for_banking.png";
import tech from "../../assets/factacy_ai_solutions_for_tech_industry.jpg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../SectionTitle";

const AiAsService = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const Navigate = useNavigate();

  return (
    <section className="mt-24 sm:px-4 sm:bg-none lg:bg-gradient-to-b from-white to-[#E8F5FF]">
      <div className="w-full h-full overflow-x-hidden xl:container">
        <div className="flex lg:flex-row sm:flex-col justify-evenly sm:items-center lg:items-center">
          <div
            className="flex mt-0 flex-col items-start gap-12 sm:w-full lg:w-96"
            style={{ height: "400px" }}
          >
            <div>
              <SectionTitle text={"AI AS A SERVICE"} />
              <div className="lg:w-[400px]">
                <h2
                  className="font-bold lg:text-4xl sm:text-2xl whitespace-normal sm:leading-2 lg:leading-10 relative z-20"
                  style={{ letterSpacing: "-0.03em" }}
                >
                  Customized Ai Solutions <br /> for your Business
                </h2>
              </div>
              <motion.svg
                className="relative -top-2 lg:w-72 sm:w-48"
                height="19"
                viewBox="0 0 478 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "loop",
                    repeatDelay: 2,
                  }}
                  d="M3.94805 15.7609C84.6154 -0.425883 274.277 0.149277 474.307 15.8478"
                  stroke="#3E91EE"
                  strokeWidth="6"
                  strokeLinecap="round"
                />
              </motion.svg>

              <div className="">
                <p
                  className="font-normal text-sm leading-6 lg:w-96 sm:w-80"
                  style={{ color: "#71717A" }}
                >
                  Benefit from our deep understanding of how AI can boost your
                  bottom-line. Our team of experts demystify the complexities
                  and enable businesses to confidently embrace and adopt AI
                  technologies.
                </p>
              </div>
            </div>

            <div>
              <div className="flex flex-col lg:w-96 sm:w-80">
                <div className="flex flex-row items-center justify-between">
                  <div className="py-2 items-center">
                    <p
                      className="font-medium font-roboto text-xl leading-8"
                      style={{ letterSpacing: "-0.02em" }}
                    >
                      Solutions by Industry
                    </p>
                  </div>
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => Navigate("/solutions")}
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0456 11.2228V7.72278L16.7122 12.3894L12.0456 17.0561V13.5561H7.37891V11.2228H12.0456ZM12.0456 0.722778C18.4856 0.722778 23.7122 5.94944 23.7122 12.3894C23.7122 18.8294 18.4856 24.0561 12.0456 24.0561C5.60557 24.0561 0.378906 18.8294 0.378906 12.3894C0.378906 5.94944 5.60557 0.722778 12.0456 0.722778ZM12.0456 21.7228C17.2022 21.7228 21.3789 17.5461 21.3789 12.3894C21.3789 7.23278 17.2022 3.05611 12.0456 3.05611C6.88891 3.05611 2.71224 7.23278 2.71224 12.3894C2.71224 17.5461 6.88891 21.7228 12.0456 21.7228Z"
                        fill="#8000FF"
                      />
                    </svg>
                  </div>
                </div>

                <hr className="border-b-1.5 border-gray" />

                <div className="flex flex-row items-center justify-between">
                  <div className="py-2">
                    <p
                      className="font-medium text-xl leading-8 font-roboto"
                      style={{ letterSpacing: "-0.02em" }}
                    >
                      Solutions by Technology
                    </p>
                  </div>
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => Navigate("/")}
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0456 11.2228V7.72278L16.7122 12.3894L12.0456 17.0561V13.5561H7.37891V11.2228H12.0456ZM12.0456 0.722778C18.4856 0.722778 23.7122 5.94944 23.7122 12.3894C23.7122 18.8294 18.4856 24.0561 12.0456 24.0561C5.60557 24.0561 0.378906 18.8294 0.378906 12.3894C0.378906 5.94944 5.60557 0.722778 12.0456 0.722778ZM12.0456 21.7228C17.2022 21.7228 21.3789 17.5461 21.3789 12.3894C21.3789 7.23278 17.2022 3.05611 12.0456 3.05611C6.88891 3.05611 2.71224 7.23278 2.71224 12.3894C2.71224 17.5461 6.88891 21.7228 12.0456 21.7228Z"
                        fill="#8000FF"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className="relative overflow-hidden">
            {isDesktop ? (
              <div
                className="absolute z-10  lg:w-96 sm:w-full"
                style={{
                  height: "510px",
                  background:
                    "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 33.07%, rgba(255, 255, 255, 0) 81.52%, #E8F5FF 100%)",
                  scrollbarWidth: "none",
                }}
              ></div>
            ) : (
              <div
                className="absolute z-10  lg:w-96 sm:w-full"
                style={{
                  height: "510px",
                  background:
                    "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 33.07%, rgba(255, 255, 255, 0) 81.52%, #FFFFFF 100%)",
                  scrollbarWidth: "none",
                }}
              ></div>
            )}

            <div
              className="z-0 flex flex-row w-max gap-4 h-max relative justify-between"
              style={{ height: "500px", scrollbarWidth: "none" }}
            >
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -400 }}
                transition={{
                  duration: 16,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="flex flex-col gap-4 "
              >
                <div className="object-contain ">
                  <img
                    src={netAddition}
                    alt="Factacy enabling business with advanced Ai as a service solutions"
                    className="w-40 h-60 rounded-2xl object-cover"
                  ></img>
                </div>
                <div className="w-40 justify-evenly p-4 rounded-2xl gap-0 bg-white flex flex-col">
                  <div className="items-center px-3.5 py-2.5 bg-blue rounded-full w-fit h-fit">
                    <p className="leading-3 text-xs font-normal">
                      Net Addition
                    </p>
                  </div>
                  <div className="items-start mt-1">
                    <p
                      className="font-bold text-xl "
                      style={{ lineHeight: "30px", letterSpacing: "-0.03em" }}
                    >
                      $2.6+ trillion
                    </p>
                  </div>
                  <div className="items-center">
                    <p
                      className="font-normal text-xs leading-6"
                      style={{ color: "#649771" }}
                    >
                      Across 63 use cases
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    src={banking}
                    alt="Factacy's Ai as a service solutions for the banking and finance industry"
                    className="object-left w-40 h-60 rounded-full object-cover"
                  ></img>
                </div>
                <div className="w-40 justify-evenly p-4 rounded-2xl gap-0 bg-white flex flex-col">
                  <div className="items-center px-3.5 py-2.5 bg-blue rounded-full w-fit h-fit">
                    <p className="leading-3 text-xs font-normal">
                      Additional Value
                    </p>
                  </div>
                  <div className="items-start mt-1">
                    <p
                      className="font-bold text-xl "
                      style={{ lineHeight: "30px", letterSpacing: "-0.03em" }}
                    >
                      $200+ billion
                    </p>
                  </div>
                  <div className="items-center">
                    <p
                      className="font-normal text-xs leading-6"
                      style={{ color: "#649771" }}
                    >
                      Across Banking Industry
                    </p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ y: -300 }}
                animate={{ y: 150 }}
                transition={{
                  duration: 16,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="flex flex-col gap-4"
              >
                <div>
                  <img
                    src={manufacturing}
                    alt="Factacy's Ai as a service solutions for the manufacturing Industry"
                    className="w-40 h-60 rounded-full object-cover object-left"
                  ></img>
                </div>

                <div className="w-40 justify-evenly p-4 rounded-2xl gap-0 bg-white flex flex-col">
                  <div className="items-center px-3.5 py-2.5 bg-red rounded-full w-fit h-fit">
                    <p className="leading-3 text-xs font-normal">
                      Manufacturing
                    </p>
                  </div>
                  <div className="items-start mt-1">
                    <p
                      className="font-bold text-xl "
                      style={{ lineHeight: "30px", letterSpacing: "-0.03em" }}
                    >
                      $170+ billion
                    </p>
                  </div>
                  <div className="items-center">
                    <p
                      className="font-normal text-xs leading-6"
                      style={{ color: "#649771" }}
                    >
                      Additional Value
                    </p>
                  </div>
                </div>

                <div>
                  <img
                    src={tech}
                    alt="Factacy's Ai as a service solutions for medical industry"
                    className="w-40 h-60 rounded-2xl object-right object-cover"
                  ></img>
                </div>

                <div className="w-40 justify-evenly p-4 rounded-2xl gap-0 bg-white flex flex-col">
                  <div className="items-center px-3.5 py-2.5 bg-red rounded-full w-fit h-fit">
                    <p className="leading-3 text-xs font-normal">Hi-tech</p>
                  </div>
                  <div className="items-start mt-1">
                    <p
                      className="font-bold text-xl "
                      style={{ lineHeight: "30px", letterSpacing: "-0.03em" }}
                    >
                      $240+ billion
                    </p>
                  </div>
                  <div className="items-center">
                    <p
                      className="font-normal text-xs leading-6"
                      style={{ color: "#649771" }}
                    >
                      Additional Value
                    </p>
                  </div>
                </div>

                <div className="flex flex-col justify-between gap-4 w-40">
                  <div
                    className="items-center px-3.5 py-2.5 bg-blue rounded-full"
                    style={{ height: "30px" }}
                  >
                    <p className=" text-sm font-base leading-3">Healthcare</p>
                  </div>
                  <div
                    className="items-center px-3.5 py-2.5 bg-purple rounded-full"
                    style={{ height: "30px" }}
                  >
                    <p className=" text-sm leading-3 font-base">
                      Financial Services
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AiAsService;
