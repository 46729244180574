export const useCaseSvgs = [
  () => (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.46 40.0001V22.6668C11.46 16.0394 16.8325 10.6668 23.46 10.6668C30.0874 10.6668 35.46 16.0394 35.46 22.6668V41.3335C35.46 45.0153 38.4448 48.0001 42.1266 48.0001C45.8085 48.0001 48.7933 45.0153 48.7933 41.3335V23.5449C45.6861 22.4467 43.46 19.4834 43.46 16.0001C43.46 11.5819 47.0416 8.00012 51.46 8.00012C55.8784 8.00012 59.46 11.5819 59.46 16.0001C59.46 19.4834 57.2338 22.4467 54.1266 23.5449V41.3335C54.1266 47.9609 48.7541 53.3335 42.1266 53.3335C35.4992 53.3335 30.1266 47.9609 30.1266 41.3335V22.6668C30.1266 18.9849 27.1419 16.0001 23.46 16.0001C19.7781 16.0001 16.7933 18.9849 16.7933 22.6668V40.0001H24.7933L14.1266 53.3335L3.45996 40.0001H11.46Z"
        fill="#FF7F7F"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.1227 21.6667H54.1227L62.1227 32.4819V48.3334H56.695C56.0478 52.8563 52.1579 56.3334 47.4561 56.3334C42.7542 56.3334 38.8643 52.8563 38.2171 48.3334H24.6949C24.0479 52.8563 20.158 56.3334 15.4561 56.3334C10.7541 56.3334 6.86421 52.8563 6.21723 48.3334H3.45605V16.3334C3.45605 14.8607 4.64997 13.6667 6.12272 13.6667H43.4561C44.9289 13.6667 46.1227 14.8607 46.1227 16.3334V21.6667ZM46.1227 27.0001V35.0001H56.7894V34.2401L51.4339 27.0001H46.1227Z"
        fill="#B5EEC3"
      />
      <path
        d="M12.0413 28.3556C13.804 28.3556 15.236 29.7876 15.236 31.5449C15.236 33.3049 13.804 34.7396 12.0413 34.7396C10.2787 34.7396 8.84668 33.3049 8.84668 31.5449C8.84668 29.7876 10.2787 28.3556 12.0413 28.3556ZM23.9853 28.3556C25.748 28.3556 27.18 29.7876 27.18 31.5449C27.18 33.3049 25.748 34.7396 23.9853 34.7396C22.2227 34.7396 20.7907 33.3049 20.7907 31.5449C20.7907 29.7876 22.2227 28.3556 23.9853 28.3556ZM35.9307 28.3556C37.6933 28.3556 39.1253 29.7876 39.1253 31.5449C39.1253 33.3049 37.6933 34.7396 35.9307 34.7396C34.168 34.7396 32.736 33.3049 32.736 31.5449C32.736 29.7876 34.168 28.3556 35.9307 28.3556Z"
        fill="#40664A"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.1188 27L46.1188 56.3334L56.7855 56.3334C58.2582 56.3334 59.4521 55.1395 59.4521 53.6667L59.4522 27L46.1188 27ZM24.7855 27L24.7855 56.3334L40.7855 56.3334L40.7855 27L24.7855 27ZM6.11882 27L6.11882 53.6667C6.11882 55.1394 7.31268 56.3334 8.78548 56.3334L19.4522 56.3334L19.4522 27L6.11882 27ZM8.78549 8.33337C7.31269 8.33337 6.11882 9.52724 6.11882 11L6.11882 21.6667L59.4522 21.6667L59.4522 11C59.4522 9.52724 58.2582 8.33337 56.7855 8.33337L8.78549 8.33337Z"
        fill="#CCBA23"
      />
      <path
        d="M46.1188 27L46.1188 56.3334L56.7855 56.3334C58.2582 56.3334 59.4521 55.1395 59.4521 53.6667L59.4522 27L46.1188 27ZM24.7855 27L24.7855 56.3334L40.7855 56.3334L40.7855 27L24.7855 27ZM6.11882 27L6.11881 53.6667C6.11881 55.1394 7.31268 56.3334 8.78548 56.3334L19.4521 56.3334L19.4522 27L6.11882 27Z"
        fill="#FAE243"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.4597 16.4593L32.7874 3.33325L10.1152 16.4593L32.7874 29.5853L55.4597 16.4593ZM7.4541 21.0813V47.3333L30.1208 60.4562V34.2039L7.4541 21.0813ZM35.4541 60.4562L58.1208 47.3333V21.0813L35.4541 34.2039V60.4562Z"
        fill="#8FCCFF"
      />
      <path
        d="M7.4541 21.0813V47.3333L30.1208 60.4562V34.2039L7.4541 21.0813ZM35.4541 60.4562L58.1208 47.3333V21.0813L35.4541 34.2039V60.4562Z"
        fill="#3E91EE"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.874 10.6663V7.99967C18.874 6.52693 20.0679 5.33301 21.5407 5.33301H42.874C44.3468 5.33301 45.5407 6.52693 45.5407 7.99967V10.6663H53.5407C53.5407 17.333 46.874 19.9997 45.5407 19.9997V26.6663H53.5407C53.5407 33.333 46.874 35.9997 45.5407 35.9997V42.6663H53.5407C53.5407 49.333 46.874 51.9997 45.5407 51.9997V55.9997C45.5407 57.4725 44.3468 58.6663 42.874 58.6663H21.5407C20.0679 58.6663 18.874 57.4725 18.874 55.9997V51.9997C17.5407 51.9997 10.874 49.333 10.874 42.6663H18.874V35.9997C17.5407 35.9997 10.874 33.333 10.874 26.6663H18.874V19.9997C17.5407 19.9997 10.874 17.333 10.874 10.6663H18.874ZM32.2074 53.333C35.153 53.333 37.5407 50.9453 37.5407 47.9997C37.5407 45.0541 35.153 42.6663 32.2074 42.6663C29.2618 42.6663 26.874 45.0541 26.874 47.9997C26.874 50.9453 29.2618 53.333 32.2074 53.333ZM32.2074 37.333C35.153 37.333 37.5407 34.9453 37.5407 31.9997C37.5407 29.0541 35.153 26.6663 32.2074 26.6663C29.2618 26.6663 26.874 29.0541 26.874 31.9997C26.874 34.9453 29.2618 37.333 32.2074 37.333ZM32.2074 21.333C35.153 21.333 37.5407 18.9452 37.5407 15.9997C37.5407 13.0542 35.153 10.6663 32.2074 10.6663C29.2618 10.6663 26.874 13.0542 26.874 15.9997C26.874 18.9452 29.2618 21.333 32.2074 21.333Z"
        fill="#C68BFF"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.12598 10.6485C6.12598 9.18548 7.34014 7.99945 8.77078 7.99945H56.8145C58.2753 7.99945 59.4593 9.18598 59.4593 10.6485V53.3504C59.4593 54.8133 58.2452 55.9995 56.8145 55.9995H8.77078C7.31011 55.9995 6.12598 54.813 6.12598 53.3504V10.6485ZM32.7926 39.9995C28.3742 39.9995 24.7926 36.4179 24.7926 31.9995C24.7926 27.5811 28.3742 23.9995 32.7926 23.9995C37.211 23.9995 40.7926 27.5811 40.7926 31.9995C40.7926 36.4179 37.211 39.9995 32.7926 39.9995ZM32.7926 45.3328C40.1564 45.3328 46.126 39.3632 46.126 31.9995C46.126 24.6357 40.1564 18.6661 32.7926 18.6661C25.4289 18.6661 19.4593 24.6357 19.4593 31.9995C19.4593 39.3632 25.4289 45.3328 32.7926 45.3328ZM48.7926 13.3328V18.6661H54.126V13.3328H48.7926Z"
        fill="#FFB3B3"
      />
      <path
        d="M6.12598 10.6485C6.12598 9.18548 7.34014 7.99945 8.77078 7.99945H56.8145C58.2753 7.99945 59.4593 9.18598 59.4593 10.6485V53.3504C59.4593 54.8133 58.2452 55.9995 56.8145 55.9995H8.77078C7.31011 55.9995 6.12598 54.813 6.12598 53.3504V10.6485ZM32.7926 45.3328C40.1564 45.3328 46.126 39.3632 46.126 31.9995C46.126 24.6357 40.1564 18.6661 32.7926 18.6661C25.4289 18.6661 19.4593 24.6357 19.4593 31.9995C19.4593 39.3632 25.4289 45.3328 32.7926 45.3328ZM48.7926 13.3328V18.6661H54.126V13.3328H48.7926Z"
        fill="#FF7F7F"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7907 10.9993C38.3061 10.9993 43.5354 12.9129 48.0882 16.4555C52.6411 19.9697 56.5175 25.1181 59.3013 31.5569C59.5094 32.0456 59.5094 32.6171 59.3013 33.0826C53.7338 45.9601 43.8216 53.666 32.7907 53.666H32.7647C21.7598 53.666 11.8476 45.9601 6.28012 33.0826C6.07199 32.6171 6.07199 32.0456 6.28012 31.5569C11.8476 18.6767 21.7598 10.9993 32.7647 10.9993H32.7907ZM32.7907 21.9892C27.0411 21.9892 22.3842 26.6179 22.3842 32.3327C22.3842 38.0216 27.0411 42.6502 32.7907 42.6502C38.5143 42.6502 43.1712 38.0216 43.1712 32.3327C43.1712 26.6179 38.5143 21.9892 32.7907 21.9892Z"
        fill="#FAE243"
      />
      <path
        opacity="0.6"
        d="M39.2741 32.3244C39.2741 35.867 36.3603 38.7632 32.7961 38.7632C29.2058 38.7632 26.292 35.867 26.292 32.3244C26.292 31.8848 26.344 31.4736 26.4221 31.0599H26.5522C29.44 31.0599 31.7814 28.7843 31.8855 25.9373C32.1717 25.8882 32.4839 25.8597 32.7961 25.8597C36.3603 25.8597 39.2741 28.7559 39.2741 32.3244Z"
        fill="#998D0D"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5413 8.83301V51.4997H56.208V56.833H8.20801V8.83301H13.5413ZM53.3795 16.6712L59.0365 22.3281L42.8747 38.4898L34.8747 30.489L24.3698 40.9949L18.7129 35.3378L34.8747 19.1762L42.8747 27.177L53.3795 16.6712Z"
        fill="#DDBAFF"
      />
      <path
        d="M13.5413 8.83301V51.4997H56.208V56.833H8.20801V8.83301H13.5413Z"
        fill="#C68BFF"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4574 27.9002V10.8335C11.4574 9.36075 12.6513 8.16683 14.124 8.16683H27.4574V2.8335H38.124V8.16683H51.4574C52.9302 8.16683 54.124 9.36075 54.124 10.8335V27.9002L57.0192 28.7687C58.3838 29.178 59.1856 30.5876 58.84 31.9698L54.795 48.1503C54.5726 48.1612 54.3491 48.1668 54.124 48.1668C49.7624 48.1668 45.8899 46.0727 43.4574 42.8348C41.0248 46.0727 37.1523 48.1668 32.7907 48.1668C28.4291 48.1668 24.5566 46.0727 22.124 42.8348C19.6914 46.0727 15.819 48.1668 11.4574 48.1668C11.2324 48.1668 11.0088 48.1612 10.7866 48.1503L6.74141 31.9698C6.39589 30.5876 7.19768 29.178 8.56218 28.7687L11.4574 27.9002ZM16.7907 26.3002L32.7907 21.5002L40.135 23.7035L44.9579 25.1504L48.7907 26.3002V13.5002H16.7907V26.3002ZM11.4574 53.5002C15.5553 53.5002 19.2933 51.9596 22.124 49.426C24.9547 51.9596 28.6928 53.5002 32.7907 53.5002C36.8886 53.5002 40.6267 51.9596 43.4574 49.426C46.288 51.9596 50.0262 53.5002 54.124 53.5002H59.4574V58.8335H54.124C50.2384 58.8335 46.5952 57.7946 43.4574 55.9794C40.3195 57.7946 36.6763 58.8335 32.7907 58.8335C28.9051 58.8335 25.2619 57.7946 22.124 55.9794C18.9862 57.7946 15.3431 58.8335 11.4574 58.8335H6.12402V53.5002H11.4574Z"
        fill="#40664A"
      />
      <path
        d="M11.4574 27.9002V10.8335C11.4574 9.36075 12.6513 8.16683 14.124 8.16683H27.4574V2.8335H38.124V8.16683H51.4574C52.9302 8.16683 54.124 9.36075 54.124 10.8335V27.9002L57.0192 28.7687C58.3838 29.178 59.1856 30.5876 58.84 31.9698L54.795 48.1503C54.5726 48.1612 54.3491 48.1668 54.124 48.1668C49.7624 48.1668 45.8899 46.0727 43.4574 42.8348C41.0248 46.0727 37.1523 48.1668 32.7907 48.1668C28.4291 48.1668 24.5566 46.0727 22.124 42.8348C19.6914 46.0727 15.819 48.1668 11.4574 48.1668C11.2324 48.1668 11.0088 48.1612 10.7866 48.1503L6.74141 31.9698C6.39589 30.5876 7.19768 29.178 8.56218 28.7687L11.4574 27.9002ZM16.7907 26.3002L32.7907 21.5002L40.135 23.7035L44.9579 25.1504L48.7907 26.3002V13.5002H16.7907V26.3002Z"
        fill="#B5EEC3"
      />
    </svg>
  ),
  () => (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.78678 50.8332V10.8332C8.78678 9.36042 9.9807 8.1665 11.4535 8.1665H35.4534C36.9262 8.1665 38.1201 9.36042 38.1201 10.8332V32.1665H43.4534C46.399 32.1665 48.7868 34.5542 48.7868 37.4998V48.1665C48.7868 49.6393 49.9806 50.8332 51.4534 50.8332C52.9262 50.8332 54.1201 49.6393 54.1201 48.1665V29.4998H48.7868C47.314 29.4998 46.1201 28.306 46.1201 26.8332V17.2711L41.7017 12.8528L45.4732 9.08157L58.6724 22.2809C59.1932 22.8016 59.4534 23.4841 59.4534 24.1665V48.1665C59.4534 52.5849 55.8718 56.1665 51.4534 56.1665C47.035 56.1665 43.4534 52.5849 43.4534 48.1665V37.4998H38.1201V50.8332H40.7868V56.1665H6.12012V50.8332H8.78678ZM14.1201 13.4998V29.4998H32.7868V13.4998H14.1201Z"
        fill="#3E91EE"
      />
    </svg>
  ),
];
