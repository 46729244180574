import React from "react";

const Speed = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9997 35.5552L7.40594 51.9509C6.79325 52.3595 5.96541 52.1939 5.55693 51.5811C5.41093 51.3621 5.33301 51.1048 5.33301 50.8416V13.1576C5.33301 12.4212 5.92997 11.8242 6.66634 11.8242C6.92957 11.8242 7.18693 11.9021 7.40594 12.0482L31.9997 28.444V13.1576C31.9997 12.4212 32.5967 11.8242 33.333 11.8242C33.5962 11.8242 33.8535 11.9021 34.0727 12.0482L62.3357 30.8901C62.9482 31.2987 63.1138 32.1264 62.7053 32.7392C62.6077 32.8856 62.4821 33.0112 62.3357 33.1088L34.0727 51.9509C33.4599 52.3595 32.6322 52.1939 32.2237 51.5811C32.0775 51.3621 31.9997 51.1048 31.9997 50.8416V35.5552Z"
        fill="#332D00"
      ></path>
      <mask
        id="mask0_311_2552"
        maskUnits="userSpaceOnUse"
        x="5"
        y="11"
        width="58"
        height="42"
        style={{ "maskType": "alpha" }}
      >
        <path
          d="M31.9997 35.5552L7.40594 51.9509C6.79325 52.3595 5.96541 52.1939 5.55693 51.5811C5.41093 51.3621 5.33301 51.1048 5.33301 50.8416V13.1576C5.33301 12.4212 5.92997 11.8242 6.66634 11.8242C6.92957 11.8242 7.18693 11.9021 7.40594 12.0482L31.9997 28.444V13.1576C31.9997 12.4212 32.5967 11.8242 33.333 11.8242C33.5962 11.8242 33.8535 11.9021 34.0727 12.0482L62.3357 30.8901C62.9482 31.2987 63.1138 32.1264 62.7053 32.7392C62.6077 32.8856 62.4821 33.0112 62.3357 33.1088L34.0727 51.9509C33.4599 52.3595 32.6322 52.1939 32.2237 51.5811C32.0775 51.3621 31.9997 51.1048 31.9997 50.8416V35.5552Z"
          fill="white"
        ></path>
      </mask>
      <g mask="url(#mask0_311_2552)">
        <rect
          x="32"
          y="11.3562"
          width="31.918"
          height="40.8188"
          fill="#FAE243"
        ></rect>
      </g>
    </svg>
  );
};

export default Speed;