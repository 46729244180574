import React from "react";

const CustomizedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
    >
      <path
        d="M14.2099 8.81129C17.4981 7.64227 21.3108 8.37371 23.9428 11.0056C26.5754 13.6383 27.3066 17.4525 26.1361 20.7413L55.0554 49.6609L49.3986 55.3177L20.4763 26.3993C17.1881 27.5684 13.3754 26.8369 10.7434 24.205C8.11077 21.5723 7.37965 17.7581 8.55008 14.4693L14.5147 20.4337C16.0768 21.9958 18.6094 21.9958 20.1715 20.4337C21.7336 18.8716 21.7336 16.339 20.1715 14.7769L14.2099 8.81129ZM41.8562 13.8341L50.3415 9.12003L54.1127 12.8913L49.3986 21.3765L44.6847 22.3193L39.0276 27.9761L35.2564 24.205L40.9132 18.5481L41.8562 13.8341ZM23 34.5759L28.6567 40.2327L15.4575 53.4321C13.8954 54.9943 11.3627 54.9943 9.80063 53.4321C8.33042 51.9617 8.24394 49.6319 9.5412 48.0604L9.80063 47.7751L23 34.5759Z"
        fill="#FF9D9D"
      />
      <path
        d="M14.2099 8.81129C17.4981 7.64227 21.3108 8.37371 23.9428 11.0056C26.5754 13.6383 27.3066 17.4525 26.1361 20.7413L55.0554 49.6609L49.3986 55.3177L20.4763 26.3993C17.1881 27.5684 13.3754 26.8369 10.7434 24.205C8.11077 21.5723 7.37965 17.7581 8.55008 14.4693L14.5147 20.4337C16.0768 21.9958 18.6094 21.9958 20.1715 20.4337C21.7336 18.8716 21.7336 16.339 20.1715 14.7769L14.2099 8.81129Z"
        fill="#FF7F7F"
      />
    </svg>
  );
};

export default CustomizedIcon;
