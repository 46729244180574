import React from "react";
import microsoft from "../../assets/microsoft-startups-india.png";
import nasscom from "../../assets/deeptech.png";
import startups from "../../assets/startups.png";
import { motion } from "framer-motion";
import TwitterIcon from "../../assets/Icons/Twitter";
import FacebookIcon from "../../assets/Icons/Facebook";
import Linkedin from "../../assets/Icons/Linkedin";
import FactacyFullIcon from "../../assets/Icons/FactacyFullIcon";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Footer = () => {
  const Navigate = useNavigate();
  const dt = new Date();
  return (
    <footer className="p-4 lg:p-0 bg-white">
      <div className="pb-4 lg:px-16 sm:px-2 py-6\2 flex flex-col lg:gap-6 sm:gap-8 h-fit w-full  xl:container">
        <div className="flex lg:flex-row sm:flex-col justify-between">
          <div>
            <p className="font-bold lg:text-4xl sm:text-3xl tracking-tight font-roboto top-4 relative z-20">
              See how Factacy can <br /> support your goals?
            </p>
            <motion.svg
              className="relative top-2 z-10 lg:w-[322px] sm:w-64"
              height="10"
              viewBox="0 0 422 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "loop",
                  repeatDelay: 2,
                }}
                d="M3.00374 6.88428C74.2189 2.34184 241.63 2.36895 418.182 6.58028"
                stroke="#3E91EE"
                strokeWidth="6"
                strokeLinecap="round"
              />
            </motion.svg>
          </div>

          <div className="flex flex-row justify-between sm:mt-5 lg:mt-8 gap-4">
            <a
              href="mailto:connect@factacy.ai"
              className="bg-buttonCTA py-4 lg:px-8 sm:px-5 rounded-full text-base leading-6 font-normal text-white w-fit h-14"
            >
              Chat With Us
            </a>
          </div>
        </div>

        <hr style={{ border: "1px solid #D4D4D8" }} />

        <div className=" flex lg:flex-row sm:flex-col justify-between sm:gap-8">
          <div className="flex lg:flex-col sm:flex-row justify-between">
            <div className="flex flex-col gap-8">
              <div className=" w-auto h-auto m-0 py-0">
                <FactacyFullIcon />
              </div>
              <div>
                <p
                  className="font-normal font-roboto lg:text-base sm:text-sm relative bottom-8"
                  style={{ color: "#52525B" }}
                >
                  Building AI solutions for your business problems.
                </p>
              </div>
              <div className="flex flex-col">
                <div className=" flex gap-4 flex-wrap">
                  <img
                    src={microsoft}
                    className="w-32 h-14 grayscale"
                    alt="partners, clients and contributors at Factacy - Microsoft"
                  ></img>
                  <img
                    src={startups}
                    className="w-36 h-14 grayscale"
                    alt="partners, clients and contributors at Factacy - StartupsIndia"
                  ></img>
                  <img
                    src={nasscom}
                    className="w-36 h-14 grayscale"
                    alt="partners, clients and contributors at Factacy - Nasscom"
                  ></img>
                </div>
                <p className="text-zinc-500 font-semibold lg:relative top-10">
                  Location: <br /> 21B, Udyog Vihar, Sector 18, Gurugram <br />{" "}
                  Haryana, India 122008
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:gap-8 sm:gap-6 ">
            <div className="flex lg:flex-row sm:flex-col lg:gap-28 sm:gap-6 justify-between">
              <div className="flex flex-col lg:gap-y-3 sm:gap-y-2 sm:text-sm">
                <p>Products</p>
                <a
                  className="text-zinc-500 hover:text-sky-600"
                  href="https://www.aicite.ai"
                  target="_blank"
                >
                  Aicite
                </a>
                <a
                  className="text-zinc-500 hover:text-sky-600"
                  href="https://www.factacyinsights.com"
                  target="_blank"
                >
                  Insights
                </a>
                <a className="text-zinc-500 hover:text-sky-600" href="/">
                  VIP
                </a>
              </div>
              <div className="flex flex-col lg:gap-y-3 sm:gap-y-2 relative sm:text-sm lg:right-8">
                <p>Resources</p>
                <span
                  className=" cursor-pointer text-zinc-500 hover:text-sky-600"
                  onClick={() => Navigate("/about-us")}
                >
                  About Us
                </span>
                <span className=" cursor-pointer text-zinc-500 hover:text-sky-600">
                  Blog
                </span>
                <span
                  className=" cursor-pointer text-zinc-500 hover:text-sky-600"
                  onClick={() => Navigate("/contact-us")}
                >
                  Contact Us
                </span>
                <span
                  className=" cursor-pointer text-zinc-500 hover:text-sky-600"
                  onClick={() => Navigate("/career")}
                >
                  Career
                </span>
              </div>
            </div>

            <div className="flex lg:flex-row sm:flex-col lg:gap-28 sm:text-sm sm:gap-6">
              <div className="flex flex-col lg:gap-y-3 sm:gap-y-2">
                <p>Solutions</p>
                <span
                  className="text-zinc-500 hover:text-sky-600"
                  onClick={() => Navigate("/solutions")}
                >
                  By Industry
                </span>
                <a className=" text-zinc-500 hover:text-sky-600" href="">
                  By Technology
                </a>
              </div>
              <div className="flex flex-col gap-3">
                <p>Contact</p>
                <div className="flex flex-row gap-2 cursor-pointer">
                  <a
                    href="https://www.linkedin.com/company/factacy/"
                    target="_blank"
                  >
                    <Linkedin />
                  </a>

                  <a href="https://twitter.com/FactacyAI/" target="_blank">
                    <TwitterIcon />
                  </a>

                  <a
                    href="https://www.facebook.com/factacydotAI/"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="flex flex-col leading-5 lg:text-sm sm:text-xs"
              style={{ color: "#52525B" }}
            >
              <p>@ {dt.getFullYear()} Factacy.ai</p>
              <div className="flex flex-row gap-1">
                <span
                  className=" cursor-pointer"
                  onClick={() => Navigate("/terms-and-conditions")}
                >
                  Terms and conditions
                </span>
                <span> | </span>
                <span
                  className=" cursor-pointer"
                  onClick={() => Navigate("/privacy-policy")}
                >
                  Privacy Policy
                </span>
                <span> | </span>
                <span
                  className=" cursor-pointer"
                  onClick={() => Navigate("/cookie-policy")}
                >
                  {" "}
                  Cookie Policy
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
