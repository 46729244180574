import React, { useState, useEffect } from "react";
import close from "../../assets/close-icon.svg";
import whatsapp from "../../assets/Contact-us/whatsapp.svg"
import mail from "../../assets/Contact-us/gmail.png"
import calender from "../../assets/Contact-us/calendar.png"
import location from "../../assets/Contact-us/location.png"
import form from "../../assets/Contact-us/google-forms.png"
import bot from "../../assets/Contact-us/bot.png"

const Chat = ({ setOpenChatBot }) => {
    const options = [
        {
            type: "Mail",
            link: "mailto:connect@factacy.ai",
            img : mail
        },
        {
            type: "Whatsapp",
            link: "https://wa.me/917290980980",
            img : whatsapp
        },
        {
            type: "Chatbot",
            link: "/ask-chat-bot",
            img : bot,
            enable : "disabled"
        },
        // {
        //     type: "Form",
        //     link: "link",
        //     img : form
        // },
        {
            type: "Calender",
            link: "https://outlook.office365.com/book/BuildAIsolutionswithFactacyAI@factacy.ai/",
            img : calender
        },
        {
            type: "Location",
            link: "/contact-us",
            img : location
        }
    ];
    return (
        <div
            className=" fixed z-[100] w-screen flex items-center justify-center bg-[#1b1b1b91]"
            style={{ height: "calc(100vh ", top: "0px" }}
        >
            <div className="bg-white p-8  w-[35rem]  fadeIncrease rounded-lg shadow-2xl">
                <div className="flex justify-between">
                    <p> Contact us via</p>
                    <img
                        className="cursor-pointer"
                        src={close}
                        onClick={() => setOpenChatBot(false)}
                    />
                </div>
                <div className="flex justify-around flex-wrap pt-4">
                    {options.map((item, i) =>  <button className="w-10 flex flex-col gap-2 items-center group" disabled={item?.enable === 'disabled'}  onClick={() => window.open(item.link)} ><img key={i} className="w-full grayscale group-hover:grayscale-0" src={item.img} /><p className="text-sm hover:drop-shadow-xl">{item.type} </p></button>)}
                </div>
            </div>
        </div>
    );
};

export default Chat;
