import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "../assets/Icons/SearchIcon";
import CarrerHomeIcon from "../assets/Icons/CarrerHomeIcon";

const CarrerPage = () => {
  const pageTitle = "Factacy Careers";
  const p = "We have visionary achievers in our team, wanted to be part of us ?";

  const [showJobs, setShowJobs] = useState();
  const firstBox = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="py-20 px-4 flex gap-4 flex-col items-start lg:py-24 lg:px-32">
        <div
          ref={firstBox}
          className="flex sm:flex-col-reverse lg:flex-row gap-5 items-center"
        >
          <div className="flex-1 flex flex-col gap-4">
            <span className=" text-lg capitalize text-[#3E91EE text-primaryBlue">
              {pageTitle}
            </span>
            <h1 className="text-4xl font-bold font-sans">
              Find Your Best <br></br> Opportunity
            </h1>
            <p>{p}</p>
            {!showJobs && (
              <button
                className=" bg-primaryBlue text-white px-6 py-2 rounded-sm w-max"
                onClick={() => {
                  setShowJobs(true);

                  window.scrollTo({
                    top: firstBox.current.scrollHeight - 10,
                    behavior: "smooth",
                  });
                }}
              >
                {"Search Jobs"}
              </button>
            )}
          </div>
          <div className="lg:flex-1">
            <CarrerHomeIcon />
          </div>
        </div>

        {showJobs && (
          <div className="mt-8">
            <div className="flex gap-2 w-full border-2 p-2 rounded-md bg-white border-blue ">
              <input
                className="flex-1 border-none outline-none"
                type="text"
                placeholder="Search job title"
              />
              <SearchIcon bg={"#3E91EE"} />
            </div>
            <p className="mt-2">Currently {0} openings at Factacy</p>
          </div>
        )}
      </div>

      {/* All jobs will list here */}
      <div className=" min-h-screen w-full"></div>
    </>
  );
};

export default CarrerPage;
